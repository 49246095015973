.modalWhatsApp {
    position: fixed;
    z-index: 33;
    right: 20px;
    bottom: 40px;
    width: auto;
    height: auto;
    background-color: green;
    display: block;
    padding: 0 0 0 12px;
    border-radius: 30px;
    @include breakpoint(768px, max){
        top: auto;
        transform: none;
        bottom: 20px;
    }
    i {
        color: $valid;
        text-align: center;
        font-size: 20px;
        padding: 12px;
        background-color: #fff;
        border-radius: 50%;
        
    }
    span {
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        transform: translateY(-2px);
        margin-right: 10px;
    }
}