.texto {
    color: $cinza;
    font-size: 16px;
    line-height: 24px;
    color: #2B3E49;
    @include breakpoint(tablet) {
        text-align: center;
    }
    p {
        strong {
            font-weight: 700;
        }
    }
}