.clientesPage {
    padding-top: 0;
    @include breakpoint(tablet) {
        margin-top: -25px;
    }
    h3 {
        display: table;
        margin: 40px auto 0;
    }
    .listaClientes {
        margin: 60px 0;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 25px;
        row-gap: 25px;
        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, 1fr);
        }
        .logoCliente {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            border: #f4f4f4 1px solid;
            border-radius: 6px;
            padding: 15px;
            img {
                max-height: 110px;
            }
        }
    }
}