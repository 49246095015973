h2 {
    display: inline-block;
    position: relative;
    color: $rosa;
    font-size: 30px;
    line-height: normal;
    padding-bottom: 15px;
    font-family: $fontTitulo;
    margin-bottom: 34px;
    @include breakpoint(tablet) {
        font-size: 24px;
    }

    &::before {
        position: absolute;
        content: '';
        width: 205px;
        height: 15px;
        background-image: url($img + 'header/linhaRosa.svg');
        background-repeat: no-repeat;
        background-position: center center;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
    }

    &.hBlack {
        color: #fff;
        &::before {
            background-image: url($img + 'header/linhaBranca.svg');
        }
    }

    &.text-center {
        display: block;
        width: 100%;
        text-align: center;
    }

}