.heropage {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 80px 0 140px;
    background-repeat: no-repeat;
    background-image: url($img + 'header/hero.jpg');
    background-position: center bottom;

    .frase {
        display: block;
        width: 100%;
        height: auto;
        color: #FFF;
        text-align: center;
        font-weight: bold;
        font-size: 30px;
        line-height: normal;
        @include breakpoint(tablet) {
            font-size: 16px;
        }
    }

    .linha {
        display: block;
        width: 100px;
        height: 3px;
        background-color: $azul;
        margin: 15px auto;
    }

    .nome {
        font-weight: bold;
        text-align: center;
        color: #fff;
        font-style: italic;
    }

    .mascara {
        position: absolute;
        width: 100%;
        height: 72px;
        background-image: url($img + 'header/mascara.png');
        background-repeat: no-repeat;
        background-position: center top;
        left: 0;
        right: 0;
        bottom: 0;
    }

}