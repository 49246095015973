.vitrine {
    display: block;
    width: 100%;
    position: relative;
    height: auto;

    .item {
        position: relative;
        min-height: 590px;
        @include breakpoint(tablet) {
            min-height: 100vh;
        }
        picture {
            position: relative;
            z-index: 1;
            img {
                position: relative;
                width: 100%;
                height: auto;
                min-height: 590px;
                object-fit: cover;
                @include breakpoint(tablet) {
                    min-height: 100vh;
                }
            }
        }
        .mascara {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 72px;
            background-image: url($img + 'header/mascara.png');
            background-repeat: no-repeat;
            background-position: center top;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .content {
            position: absolute;
            width: 100%;
            height: auto;
            top: 150px;
            left: 0;
            right: 0;
            z-index: 2;
            @include breakpoint(tablet) {
                top: 80px;
            }
            .blkCover {
                display: block;
                width: 100%;
                height: auto;
                padding: 30px;
                background-color: rgba($verde, .9);
                @include breakpoint(tablet) {
                    margin-bottom: 30px;
                }
            }
            h1 {
                font-weight: 700;
                font-size: 30px;
                line-height: 37px;
                color: #FFFFFF;
                margin-bottom: 20px;
                @include breakpoint(tablet) {
                    font-size: 22px;
                    line-height: 28px;
                    text-align: center;
                }
            }
        
            .linha {
                display: block;
                width: 100%;
                max-width: 115px;
                height: 1px;
                background-color: #fff;
                margin-bottom: 20px;
                @include breakpoint(tablet) {
                    margin: 20px auto;
                }
            }
        
            .texto {
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
                margin-bottom: 30px;
                @include breakpoint(tablet) {
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 30px;
                }
            }
            a {
                &.btn {
                    &-green-inverse {
                        @include breakpoint(tablet) {
                            display: block;
                            width: 100%;
                            font-size: 14px;
                            padding: 6px 10px;
                        }
                    }
                }
            }
        }
    }

}