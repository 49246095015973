.btn {
    text-transform: uppercase;
    font-size: 0.875rem;
    letter-spacing: -0.07px;
    min-height: 38px;
    &-block {
        display: block;
        width: 100%;
    }
    &-green {
        border-radius: 0;
        background-color: $verde;
        border: $verde 2px solid;
        padding: 14px 32px;
        color: #FFF;
        letter-spacing: normal;
        font-weight: bold;
        &:hover {
            background-color: $azul;
            border-color: $azul;
            color: #FFF;
        }
        &-big {
            font-size: 1.25rem;
        }
    }
    &-green-inverse {
        border-radius: 0;
        background-color: $azul;
        border: $azul 2px solid;
        padding: 14px 32px;
        color: #FFF;
        letter-spacing: normal;
        font-weight: bold;
        &:hover {
            background-color: $rosa;
            border-color: $rosa;
            color: #FFF;
        }
        &-big {
            font-size: 1.25rem;
        }
    }
    &-white {
        border-radius: 0;
        background-color: transparent;
        border: #FFF 2px solid;
        padding: 14px 32px;
        color: #FFF;
        letter-spacing: 3px;
        font-weight: bold;
        &:hover {
            background-color: #FFF;
            color: $vermelho;
        }
    }
    &-red {
        border-radius: 0;
        background-color: $vermelho;
        border: $vermelho 2px solid;
        padding: 14px 32px;
        color: #FFF;
        letter-spacing: 3px;
        font-weight: bold;
        &:hover {
            border-color: $vermelhoHover;
            background-color: $vermelhoHover;
            color: #FFF;
        }
    }
    &-grey {
        border-radius: 0;
        background-color: $cinza;
        border: $cinza 2px solid;
        padding: 14px 32px;
        color: #fff;
        letter-spacing: 3px;
        font-weight: bold;
        &:hover {
            border-color: $cinzaHover;
            background-color: $cinzaHover;
            color: #FFF;
        }
    }
}