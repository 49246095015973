.single-news {
    padding-top: 100px;
    header {
        background-color: $verde;
    }
}

.singlePost {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 60px 0;
    
    h1 {
        font-weight: bold;
        margin-bottom: 15px;
    }

    .autor {
        color: #4d4d4d;
        margin-bottom: 15px;
    }

    .foto {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 30px;
        picture {
            display: block;
            width: 100%;
            height: auto;
            img {
                display: block;
                width: 100%;
                height: auto;
                border-radius: 8px;
            }
        }
    }

    .conteudo {
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #4D4D4D;
            margin: 40px 0px;
        }

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 26px;
            color: $verde;
            margin: 0;
        }

        ul, ol {
            padding: 0 0 0 20px;
            li {
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 28px;
                color: #4D4D4D;
            }
        }

        .wp-caption {
            display: block !important;
            width: auto !important;
            max-width: 100% !important;
            height: auto !important;
        }

        img {
            display: block !important;
            width: auto !important;
            max-width: 100% !important;
            height: auto !important;
            border-radius: 16px !important;
        }

        .wp-caption-text {
            font-size: 12px;
            line-height: 17px;
            margin: 10px 0;
            color: #4D4D4D;
        }

        blockquote {
            padding: 40px;
            border-radius: 8px;
            margin: 50px 0 0;
            display: block;
            width: 100%;
            background-color: #f1f1f1;
            p {
                margin: 0;
            }
        }

        iframe {
            display: block;
            width: 100%;
            border-radius: 16px;
        }
    }

    .theTags {
        display: block;
        width: 100%;
        margin-bottom: 40px;
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 8px;
                @include breakpoint(tablet) {
                    margin-bottom: 8px;
                }
                a {
                    display: block;
                    padding: 4px 12px;
                    border: #CCC 1px solid;
                    text-decoration: none;
                    font-size: 14px;
                    line-height: normal;
                    transition: all .3s ease;
                    &:hover {
                        cursor: pointer;
                        text-decoration: none;
                        background-color: #000;
                        border-color: #000;
                        color: #fff;
                    }
                }
            }
        }
    }
    nav {
        &.share {
            @include breakpoint(tablet) {
                margin-bottom: 40px;
            }
            ul {
                padding: 0;
                li {
                    list-style-type: none;
                    display: inline-block;
                    margin-bottom: auto;
                    margin-right: 6px;
                    a {
                        display: flex;
                        align-items: center;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        font-size: 24px;
                        text-decoration: none;
                        background-color: #CCC;
                        color: #fff;
                        transition: all .3s ease;
                        i {
                            display: inline-block;
                            margin: auto;
                        }
                        &:hover {
                            text-decoration: none;
                            cursor: pointer;
                            background-color: $rosa;
                        }
                    }
                }
            }
        }
    }
}