.clientes {
    display: block;
    width: 100%;
    background-color: $azul;
    // background-image: url($img + 'geral/bgazulfull.jpg');
    // background-repeat: no-repeat;
    // background-position: center center;
    @include breakpoint(tablet) {
        padding-bottom: 40px;
    }

    h2 {
        display: table;
        margin: auto auto 34px;
    }

    .container {
        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    .content {
        display: block;
        width: 100%;
        height: auto;
        padding: 60px 40px;
        background-color: rgba($azul, .9);
        @include breakpoint(tablet) {
            padding: 60px 30px;
        }
        .texto {
            color: #fff;
            text-align: center;
        }
        .linhaBotao {
            display: block;
            text-align: center;
            a {
                display: inline-block;
                padding: 8px 45px;
                background-color: $verde;
                color: #fff;
                border-radius: 8px;
                margin: auto;
                text-transform: uppercase;
                transition: all .3s ease;
                font-size: 16px;
                line-height: 24px;
                font-family: $fontDefault;
                @include breakpoint(tablet) {
                    padding: 8px 25px;
                }
                &:hover {
                    color: #fff;
                    background-color: $verdeHover;
                }
            }
        }
    }

    .blocoLogos {
        display: block;
        background-color: #fff;
        border-radius: 30px;
        padding: 20px;
        @include breakpoint(tablet) {
            border-radius: 0;
        }
    }

    .carouselClientes {
        display: flex;
        align-items: center;
        width: 100%;
        height: auto;
        .item {
            display: flex;
            align-items: center;
            min-height: 110px;
            .logoCliente {
                display: flex;
                align-items: center;
                width: 100%;
                height: 100%;
                min-height: 110px;
                padding: 0 15px;
                img {
                    display: block;
                    max-height: 100px;
                    width: auto;
                }
            }
        }
    }

    

}