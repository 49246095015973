.destaque {
    display: block;
    width: 100%;
    height: auto;
    background-image: url($img + 'geral/banner.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 60px 0;

    h1 {
        font-weight: 700;
        font-size: 30px;
        line-height: 37px;
        color: #FFFFFF;
        margin-bottom: 20px;
        @include breakpoint(tablet) {
            font-size: 24px;
            line-height: 30px;
            text-align: center;
        }
    }

    .linha {
        display: block;
        width: 100%;
        max-width: 115px;
        height: 1px;
        background-color: #fff;
        margin-bottom: 20px;
        @include breakpoint(tablet) {
            margin: 20px auto;
        }
    }

    .texto {
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
        margin-bottom: 30px;
        @include breakpoint(tablet) {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 30px;
        }
    }

}