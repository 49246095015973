.solucoesPage {
    padding-top: 0;
    @include breakpoint(tablet) {
        margin-top: -25px;
    }
    h2 {
        font-family: $fontDefault;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        margin-top: 40px;
        &::before {
            display: none;
        }
    }
    .chamada {
        display: block;
        line-height: normal;
        margin-bottom: 50px;
        text-align: center;
        font-size: 20px;
    }
    h3 {
        display: table;
        margin: 40px auto 0;
        font-weight: 700;
        text-align: center;
        border-bottom: #f4f4f4 2px solid;
        padding-bottom: 8px;
        margin-bottom: 30px;
        font-size: 28px;
    }

    .subchamada {
        display: block;
        text-align: center;
        font-size: 18px;
        margin-bottom: 30px;
    }

    .listSolucoes {
        .solucao {
            display: block;
            width: 100%;
            height: auto;
            margin-bottom: 50px;
            figure {
                display: block;
                width: 100%;
                height: auto;
                img {
                    display: block;
                    width: 100%;
                    height: 300px;
                    object-fit: cover;
                }
            }
            h4 {
                font-size: 18px;
                text-align: center;
                font-weight: 600;
            }
            .texto {
                font-size: 14px;
                &.textoBig {
                    text-align: center;
                    font-size: 16px;
                }
            }
        }
    }
}