header {
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: transparent;
    top: 0;
    z-index: 999;
    transition: all .3s ease;
    padding: 20px 0;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));

    .logoHeader {
        position: relative;
        z-index: 999;
        a {
            display: block;
            width: auto;
            height: auto;
            img {
                display: block;
                width: auto;
                max-width: 100%;
                height: auto;
            }
        }
    }

    .menu {
        display: block;
        .botaoResponsivo {
            position: relative;
            display: none;
            z-index: 9999;
            width: 50px;
            height: 50px;
            float: right;
            // top: -22px;
            // right: 15px;
            @include breakpoint(tablet) {
                display: block;
            }
            .linhasmr {
                float: right;
                margin-top: 8px;
                .b1 {
                    top: 14px;
                }
                .b2 {
                    top: 22px;
                }
                .b3 {
                    top: 30px;
                }
                >span {
                    background-color: #FFF;
                    height: 2px;
                    right: 0;
                    position: absolute;
                    top: 0;
                    transition: all 0.3s ease 0s;
                    width: 32px;
                    border-radius: 0px;
                }
            }
            &.active {
                // position: fixed;
                // right: 95px;
                // top: 30px;
                @include breakpoint(mob) {
                    // right: 30px;
                }
                .linhasmr {
                    >span {
                        background-color: #FFF !important;
                    }
                    .b1 {
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        top: 21px;
                        right: 0;
                    }
                    .b2 {
                        width: 0px;
                    }
                    .b3 {
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        top: 21px;
                        right: 0;
                    }
                }
            }
        }
        nav {
            display: block;
            width: 100%;
            @include breakpoint(tablet) {
                position: fixed;
                right: -100vw;
                height: 100vh;
                width: 100vw;
                top: 0;
                background-color: $verde;
                transition: all .3s ease;
            }
            ul {
                display: flex;
                // justify-content: space-between;
                margin: 0;
                padding: 0;
                align-items: center;
                justify-content: flex-end;
                @include breakpoint(tablet) {
                    padding: 130px 30px 0;
                    display: block;
                }
                li {
                    list-style-type: none;
                    display: inline-block;
                    padding: 0 10px;
                    @include breakpoint(tablet) {
                        display: block;
                    }
                    a {
                        position: relative;
                        display: block;
                        color: #FFF;
                        font-size: 14px;
                        line-height: normal;
                        font-weight: 600;
                        padding: 8px 9px;
                        transition: all .3s ease;
                        text-decoration: none;
                        text-transform: lowercase;
                        @include breakpoint(tablet) {
                            border-bottom: rgba(#FFF, .2) 1px solid;
                            padding: 16px 0;
                            color: #FFF;
                        }
                        &::before {
                            position: absolute;
                            content: '';
                            width: 0;
                            left: 50%;
                            bottom: 0;
                            height: 2px;
                            transform: translateX(-50%);
                            background-color: #FFF;
                            transition: all .3s ease;
                            @include breakpoint(tablet) {
                                width: 12px;
                                height: 12px;
                                background-color: transparent;
                                background-image: url($img + 'header/seta.svg');
                                background-repeat: no-repeat;
                                background-position: center center;
                                left: auto;
                                right: 0;
                                bottom: auto;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                        &:hover {
                            cursor: pointer;
                            &::before {
                                width: 100%;
                                @include breakpoint(tablet) {
                                    width: 12px;
                                }
                            }
                        }
                    }
                    &.linksHeader {
                        display: block;
                    }
                    &.active {
                        a {
                            &::before {
                                background-color: $vermelho;
                                width: 100%;
                            }
                        }
                    }
                    &.icone {
                        padding: 0;
                        @include breakpoint(tablet) {
                            display: inline-block;
                            padding: 0 10px;
                        }
                        &-busca {
                            padding: 0 10px;
                        }
                        a {
                            font-size: 22px;
                            @include breakpoint(tablet) {
                                border: 0;
                                font-size: 30px;
                            }
                            &::before {
                                @include breakpoint(tablet) {
                                    display: none;
                                }
                            }
                            &:hover {
                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            &.active {
                right: 0;
            }
        }
    }

    &.noBack {}

    &.active, &.scroll {
        background-color: $verde;
    }

    &.forceScroll {}
    
}