.modalCookiesFooter {
    position: fixed;
    width: 100%;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-color: #FFFFFF;
    display: none;
    z-index: 999;
    padding: 20px 0;
    font-size: 14px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));

    .container {
        max-width: 790px;

        .texto {
            font-size: .875rem;
            font-weight: 300;
            line-height: 1.25rem;
            color: #000;
            @include breakpoint(mob) {
                margin-bottom: 20px;
            }
            a {
                display: block;
                color: $azul;
                text-decoration: underline;
                @include breakpoint(vertmob) {
                    display: inline-block;
                }
            }
        }

        a {
            &.btn {
                display: block;
                color: #FFF;
                background-color: $verde;
                border-radius: 0;
            }
        }
    }
}