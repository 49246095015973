.sobrePage {
    padding-top: 0;
    @include breakpoint(tablet) {
        margin-top: -25px;
    }
    .listaClientes {
        margin: 60px 0;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 25px;
        row-gap: 25px;
        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, 1fr);
        }
        .logoCliente {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            border: #f4f4f4 1px solid;
            border-radius: 6px;
            padding: 15px;
            img {
                max-height: 110px;
            }
        }
    }

    .colaboradores {
        display: block;
        width: 100%;
        height: auto;
        padding: 120px 0 30px 0;
        background-color: $azul;
        margin: 80px 0 0;
        @include breakpoint(tablet) {
            padding: 120px 0 10px;
        }

        .listColaborador {
            // display: grid;
            display: flex;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 25px;
            row-gap: 25px;
            @include breakpoint(tablet) {
                display: block;
            }
            .colaborador {
                position: relative;
                display: block;
                width: 100%;
                height: auto;
                border: #FFF 3px solid;
                background-color: rgba($azul, .9);
                border-radius: 8px;
                padding: 20px;
                color: #fff;
                @include breakpoint(tablet) {
                    margin-bottom: 80px;
                }
                .foto {
                    display: table;
                    position: relative;
                    margin: -68px auto 30px auto;
                    img {
                        border-radius: 6px;
                        border: #FFF 3px solid !important;
                    }
                }
                h3 {
                    text-transform: uppercase;
                    color: #fff;
                    display: block;
                    text-align: center;
                }
                .linha {
                    display: block;
                    width: 100%;
                    max-width: 100px;
                    height: 3px;
                    background-color: $verde;
                    margin: 15px auto;
                }
                .cargo {
                    font-weight: bold;
                    font-size: 11px;
                    text-transform: uppercase;
                    display: block;
                    text-align: center;
                }
                .info {
                    display: block;
                    text-align: center;
                    line-height: 160%;
                }
            }
        }


    }
}