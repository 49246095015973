footer {
    display: block;
    width: 100%;
    height: auto;
    // background-image: url($img + 'contato/background.jpg');
    // background-position: center bottom;
    // background-size: cover;
    // background-repeat: no-repeat;
    background-color: $azul;
    padding: 60px 0 20px;

    h2 {
        display: table;
        margin: auto auto 34px;
    }
    
    .infos {
        display: block;
        a {
            display: block;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
            text-decoration: none;
            margin-bottom: 15px;
            text-align: center;
            @include breakpoint(tablet) {
            }
            i {
                display: inline-block;
                vertical-align: middle;
                font-size: 26px;
            }
            span {
                display: inline-block;
                vertical-align: middle;
                font-weight: 600;
            }
        }
    }

    .redes {
        ul {
            margin: 0;
            padding: 0;
            text-align: center;
            @include breakpoint(tablet) {
                margin-bottom: 40px;
            }
            li {
                list-style-type: none;
                display: inline-block;
                margin-right: 10px;
                a {
                    display: block;
                    font-size: 40px;
                    color: #fff;
                    text-decoration: none;
                    &:hover {
                        color: $verde;
                    }
                }
            }
        }
    }

    .copyright {
        font-size: 12px;
        line-height: normal;
        color: #fff;
        margin-top: 50px;
        @include breakpoint(tablet) {
            margin-top: 30px;
            text-align: center;
        }
    }

    .linkDev {
        text-align: right;
        margin-top: 50px;
        @include breakpoint(tablet) {
            margin-top: 6px;
            text-align: center;
            margin-bottom: 15px;
        }
        a {
            color: #fff;
            display: inline-block;
            font-size: 12px;
            line-height: normal;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}