.news {
    display: block;
    width: 100%;
    padding: 60px 0;

    h2 {
        display: table;
        margin: auto auto 34px;
        text-align: center;
    }

    .listaPosts {
        a {
            display: block;
            text-decoration: none;
            width: 100%;
            height: 100%;
            margin-bottom: 25px;
            picture {
                display: block;
                width: 100%;
                height: auto;
                overflow: hidden;
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                    transition: all .3s ease;
                }
            }
            .conteudo {
                display: block;
                padding: 30px;
                background-color: rgba(#F1F1F1, .7);
                h3 {
                    color: $azul;
                    margin-bottom: 10px;
                }
                .texto {
                    font-size: 14px;
                    line-height: normal;
                    color: #000;
                    margin-bottom: 40px;
                    @include breakpoint(tablet) {
                        text-align: left;
                    }
                }
                .leiaMais {
                    font-size: 14px;
                    line-height: normal;
                    color: $verde;
                    text-transform: uppercase;
                    svg {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    span {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 10px;
                    }
                }
            }
            &:hover {
                picture {
                    img {
                        transform: scale(1.1);
                    }
                }
                .conteudo {
                    background-color: rgba(#F1F1F1, 1);
                    .leiaMais {
                        color: $azul;
                        svg {
                            path {
                                fill: $azul;
                            }
                        }
                    }
                }
            }
        }
    }


    .linhaBotao {
        display: block;
        text-align: center;
        a {
            display: inline-block;
            padding: 8px 45px;
            background-color: $verde;
            color: #fff;
            border-radius: 8px;
            margin: auto;
            text-transform: uppercase;
            transition: all .3s ease;
            font-size: 16px;
            line-height: 24px;
            font-family: $fontDefault;
            @include breakpoint(tablet) {
                padding: 8px 25px;
            }
            &:hover {
                color: #fff;
                background-color: $azul;
            }
        }
    }

}