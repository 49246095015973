.solucoes {
    display: block;
    width: 100%;
    background-color: $verde;
    background-color: $verde;
    background-image: url($img + 'geral/bgverdefull.jpg');
    background-repeat: no-repeat;
    background-position: center center;

    h2 {
        display: table;
        margin: auto auto 34px;
    }
    
    .container {
        @include breakpoint(tablet) {
            padding: 0;
        }
    }

    .content {
        display: block;
        width: 100%;
        height: auto;
        padding: 60px 40px;
        background-color: rgba($verde, .9);
        @include breakpoint(tablet) {
            padding: 60px 30px;
        }
        .texto {
            color: #fff;
            text-align: center;
        }
        .linhaBotao {
            display: block;
            text-align: center;
            a {
                display: inline-block;
                padding: 8px 45px;
                background-color: $azul;
                color: #fff;
                border-radius: 8px;
                margin: auto;
                text-transform: uppercase;
                transition: all .3s ease;
                font-size: 16px;
                line-height: 24px;
                font-family: $fontDefault;
                @include breakpoint(tablet) {
                    padding: 8px 25px;
                }
                &:hover {
                    color: #fff;
                    background-color: $azulHover;
                }
            }
        }
    }

    

}