/*
  SASS Variables for project
 */
$version: "v1.0.0";
$author: "Alexander Moraes";

// COLORS DEFAULT
$valid: #2ecc71;
$error: #FF0000;

$cinza: #444;
$cinzaHover: rgb(55, 55, 55);
$preto: #000;

$laranja: #FF7E5E;
$laranjaHover: #DD7358;
$rosa: #EC63AA;

$azul: #2B3E49;
$azulHover: #213038;
$verde: #47A4AD;
$verdeHover: #388188;
$vermelho: #FF0000;
$vermelhoHover: #d53636;
$cinzaClaro: #C6D4DD;
$cinza: #5f5e61;
$rosa: #E6146A;


//////////////////////////////////////////////////////
$corBordaForm: $verde;
//////////////////////////////////////////////////////

//Tamanho Container
$containerWidth: 1180px;

//Font Padrão do Site
$fontDefault: 'Montserrat', sans-serif;;
$fontTitulo: 'Pacifico', sans-serif;;

// font-family: 'Merriweather', serif;
// font-family: 'Poppins', sans-serif;

$corBarraResponsivo: #666060;

$coluna: 4vw;

// PATHS
$fontsSRC: $urlAssets + "fonts/";
$img: $urlAssets + "imgs/";


// BREAKPOINTS
$breakpoints: (max: (xs: 320px, mob: 660px, vertmob: 740px, tablet: 767px, deskmini: 1024px), min: (sm: 768px, md: 992px, lg: 1200px));