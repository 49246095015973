/*!
Theme Name: Urbim Consultoria
Theme URI: https://dominioweb.dev/
Description: Site desenvolvido especificamente para uso da Domínio WEB;
Author: Dominio WEB
Author URI: https://dominioweb.dev/
Version: 1.0
*/

$urlAssets: 'assets/';

// Imports
@import "mixins/mixins";
@import "commom/imports";
@import "commom/variables";
@import "commom/fonts";
@import "commom/default";
@import "commom/padroes";
// @import "commom/keyframes";

//Components
// @import "components/label";
// @import "components/inputs";
@import "components/buttons";
// @import "components/select";
// @import "components/compartilhe";
// @import "components/owl";
// @import "components/marcador";
// @import "components/linha";
@import "components/h2";
@import "components/h3";
@import "components/texto";
@import "components/wpForms";
@import "components/cookies";
@import "components/whatsapp";


//Layout Site
@import "parts/header";
@import "parts/footer";

@import "templates/sobre";
@import "templates/solucoes";
@import "templates/clientes";
@import "templates/news";
@import "templates/heropage";
@import "templates/vitrine";
@import "templates/destaques";

@import "pages/page";
@import "pages/news";
@import "pages/singleNews";
@import "pages/sobre";
@import "pages/clientes";
@import "pages/solucoes";
@import "pages/busca";
@import "pages/obrigado";