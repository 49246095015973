.sobre {
    display: block;
    width: 100%;
    padding: 40px 0;

    h2 {
        margin-top: 40px;
        @include breakpoint(tablet) {
            text-align: center;
            display: block;
            width: 100%;
        }
    }

    .bgBlack {
        background-color: #2B3E49;
        color: #fff;
        padding: 40px;

        h2 {
            display: block;
            text-align: center;
            margin: auto auto 34px;
        }

        .topico {
            display: flex;
            width: 100%;
            margin-bottom: 40px;
            .icone {
                display: inline-flex;
                width: 100%;
                max-width: 75px;
                margin-right: 15px;
            }
            .info {
                display: inline-flex;
                flex-direction: column;
                width: 100%;
                max-width: calc(100% - 100px);
                .infoText {
                    font-size: 14px;
                    line-height: normal;
                    padding-right: 20px;
                }
            }

        }

        h3 {
            color: #fff;
        }

        .linhaFull {
            .topico {
                flex-direction: column;
                .icone {
                    display: block;
                    margin: auto auto 15px;
                }
                .info {
                    display: block;
                    max-width: 100%;
                    text-align: center;
                    .infoText {
                        padding-right: 0;
                    }
                }
            }
        }

    }

    .imagemAbajur {
        margin-left: -110px;
        @include breakpoint(tablet) {
            display: none;
        }
        img {
            max-height: 694px;
        }
    }

    .linhaBotao {
        display: block;
        @include breakpoint(tablet) {
            margin-bottom: 40px;
            text-align: center;
        }
        a {
            display: inline-block;
            padding: 8px 45px;
            background-color: $verde;
            color: #fff;
            border-radius: 8px;
            margin: auto;
            text-transform: uppercase;
            transition: all .3s ease;
            font-size: 16px;
            line-height: 24px;
            font-family: $fontDefault;
            @include breakpoint(tablet) {
                padding: 8px 25px;
            }
            &:hover {
                color: #fff;
                background-color: $azul;
            }
        }
    }
}