.wpforms-container {
    @include breakpoint(tablet) {
        margin-top: 40px !important;
    }
    .wpforms-field {
        padding: 4px 0 !important;
    }
    input[type=text], input[type=email], input[type=tel], textarea, select {
        border-radius: 0 !important;
        height: auto !important;
        border: $corBordaForm 1px solid !important;
        font-size: 14px !important;
        line-height: normal !important;
        padding: 12px 15px !important;
        font-family: $fontDefault !important;
        color: #fff !important;
        background-color: $azul !important;
        border-color: $corBordaForm !important;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #FFF !important;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #FFF !important;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: #FFF !important;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: #FFF !important;
        }
        &:focus {
            outline: none;
        }
    }
    input[type=tel] {
        padding-left: 50px !important;
    }
    .iti__arrow {
        border-top: 4px solid $corBordaForm !important;
    }
    .iti__arrow--up {
        border-top: 4px solid $corBordaForm !important;
    }
    select {
        padding: 11px 15px !important;
    }
    textarea {
        height: 120px !important;
    }
    .wpforms-submit {
        border: 0 !important;
        font-family: $fontDefault !important;
        font-size: 16px !important;
        font-weight: bold !important;
        text-transform: uppercase !important;
        background-color: $corBordaForm !important;
        color: #FFF !important;
        font-weight: bold !important;
        text-transform: uppercase !important;
        font-size: 16px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        i {
            display: inline-block !important;
            vertical-align: middle !important;
            font-size: 24px !important;
            margin-right: 4px !important;
        }
        span {
            display: inline-block !important;
            vertical-align: middle !important;
        }
        &:hover {
            background-color: $corBordaForm !important;
            color: $azul !important;
        }
    }
}