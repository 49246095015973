.buscaPage {
    padding-top: 0;
    @include breakpoint(tablet) {
        margin-top: -25px;
    }

    .heropage {
        h1 {
            color: #fff;
            @include breakpoint(tablet) {
                font-size: 22px;
            }
        }
    }

    input {
        border: $azul;
        background-color: rgba($azul, .9);
        color: #fff;
        font-size: 20px;
        height: auto;
        padding: 10px 20px;
        @include breakpoint(tablet) {
            margin-bottom: 8px;
        }
    }

    button {
        display: inline-block;
        padding: 8px 45px;
        background-color: $azul;
        color: #fff;
        border-radius: 0.25rem;
        margin: auto;
        text-transform: uppercase;
        transition: all .3s ease;
        font-size: 16px;
        line-height: 24px;
        font-family: $fontDefault;
        height: 50px;
        @include breakpoint(tablet) {
            padding: 8px 25px;
            display: block;
            width: 100%;
        }
        &:hover {
            color: #fff;
            background-color: $azulHover;
        }
    }


    .searchResults {
        h1 {
            margin-bottom: 40px;
        }
        .resultado {
            display: block;
            width: 100%;
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: #ccc 1px solid;
            a {
                text-decoration: none;
            }
            h2 {
                font-family: $fontDefault;
                margin-bottom: 5px;
                color: #000;
                padding-bottom: 0;
                font-size: 24px;
                &::before {
                    display: none;
                }
            }
            .texto {
                p {
                    margin: 0;
                }
            }
        }
    }


}