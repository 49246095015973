* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    overflow-x: hidden !important;
    scroll-behavior: smooth;
}

body {
    font: lineHeight(14px, 120%) $fontDefault;
    font-weight: 300;
    overflow-x: hidden !important;
    padding: 0;
    margin: 0;
    width: 100vw;
    height: auto;
    background-color: #FFFFFF;
    &.blockScroll {
        overflow: hidden !important;
    }
}